/**
 * Styles targeting the homepage
 *
 */

/* =Featured Highlight
----------------------------------------------------------*/

.featured-highlight {
  position: relative;
}

.featured-highlight img {
  width: 100%;
}

.featured-highlight__welcome {
  padding: 0 20px 20px;
  text-align: center;
  @include breakpoint($md) {
    display: block;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 10%;
    left: 56%;
    width: 40%;
    max-width: 500px;
    margin: 0;
    color: $wcm-med-gray;
  }
}

.featured-highlight__welcome .container-wrap {
  @include breakpoint($sm-only) {
    @include container-wrap();
  }
}

.featured-highlight__welcome h1 {
  font-size: 26px;
  font-family: $font-family-sans-serif;
  color: $wcm-bright-orange;
  border-bottom: 2px solid $wcm-border-gray;
  padding-bottom: 20px;
  margin-bottom: 20px;
  @include breakpoint($sm) {
    font-size: 38px;
  }
  @include breakpoint($md) {
    font-size: 30px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  @include breakpoint((min: 1135px)) {
    font-size: 38px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.featured-highlight__welcome p {
  padding-bottom: 10px;
  color: $wcm-black;
  @include breakpoint($md) {
    font-size: 14px;
    padding-bottom: 0;
  }
  @include breakpoint((min: 1135px)) {
    font-size: 15px;
    padding-bottom: 15px;
  }
}

.featured-highlight__text {
  width: 100%;
  color: $wcm-black;
  font-size: 14px;
  padding: 10px 0;
  @include breakpoint($md) {
    position: absolute;
    bottom: 5%;
    padding: 15px 0;
    background: rgba(255, 255, 255, 0.9);
  }
  @include breakpoint($lg) {
    font-size: 16px;
  }
}

.featured-highlight__name {
  font-size: 20px;
  @include breakpoint($md) {
    text-align: right;
    line-height: 1.9;
  }
  @include breakpoint($lg) {
    font-size: 22px;
  }
}

.featured-highlight__pull-quote p {
  margin: 0;
  line-height: 1.6;
}

.featured-highlight__cta .btn {
  @include breakpoint($xs) {
    margin: 0 0 10px;
  }
}

/* =Why WCMC Pane
----------------------------------------------------------*/

.why-wcmc {
  padding: 40px 0;
  border-top: 1px solid $wcm-border-gray;
  @include breakpoint($md) {
    border: none;
  }
}

.why-wcmc__title {
  font-size: 24px;
  padding-bottom: 40px;
  @include breakpoint($xs) {
    padding-bottom: 10px;
  }
}

.why-wcmc__title a {
  font-size: 16px;
  padding-left: 5px;
  @include breakpoint($xs) {
    display: block;
  }
  &:after {
    content: '\e802';
    font-size: 12px;
    padding-left: 5px;
    @include fontello();
  }
}

.why-wcmc__benefit {
  text-align: center;
  @include breakpoint($xs) {
    margin: 20px 0;
  }
}

.why-wcmc__benefit a {
  display: inline-block;
  text-decoration: none;
  color: $wcm-bright-orange;
}

.why-wcmc__benefit a:hover span {
  text-decoration: underline;
}

.why-wcmc__benefit a:before {
  display: block;
  font-size: 36px;
  padding-bottom: 20px;
  color: $wcm-bright-orange;
  cursor: pointer;
  @include careers-icon();
}

.why-wcmc__benefit a:hover:before {
  //text-decoration: none;
}

.why-wcmc__benefit--comp a:before {
  content: '\e903';
}

.why-wcmc__benefit--dev a:before {
  content: '\e901';
}

.why-wcmc__benefit--balance a:before {
  content: '\e902';
}

.why-wcmc__benefit--awards a:before {
  content: '\e905';
}

/* =Find Your Place
----------------------------------------------------------*/

.find-your-place {
  position: relative;
}

.find-your-place__image {
  background-image: url(../images/home/careers-1501.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 175px;
  @include breakpoint($sm) {
    height: 300px;
  }
  &:nth-child(2) {
    background-image: url(../images/home/careers-1103.jpg);
    border-left: 3px solid $wcm-white;
  }
}

.find-your-place__career-areas {
  padding: 0 20px;
  @include breakpoint($xs) {
    width: 90%;
    max-width: 315px;
    margin: 0 auto 20px;
  }
  @include breakpoint($sm) {
    position: absolute;
    top: 0;
    right: 5%;
    height: 100%;
    // overflow: hidden;
    background: rgba(255, 255, 255, 0.9);
  }
}

.find-your-place__career-areas h3 {
  color: $wcm-black;
  font-family: $font-family-sans-serif;
  margin: 10px 0 5px 0;
  @include breakpoint($sm) {
    max-width: 225px;
    text-align: center;
  }
}

.find-your-place__career-areas ul {
  padding: 5px 0 0 20px;
  @include breakpoint($sm) {
    margin-bottom: 5px;
  }
}

.find-your-place__career-areas li {
  padding-bottom: 8px;
}

/* =Featured Jobs Home View
----------------------------------------------------------*/

.featured-jobs {
  padding: 40px 0;
  border-top: 1px solid $wcm-border-gray;
  @include breakpoint($md) {
    border: none;
  }
}

.featured-jobs .view-jobs {
  @include container-wrap();
}

.featured-jobs__title {
  font-size: 24px;
  padding-bottom: 40px;
}

.featured-jobs__title a {
  font-size: 16px;
  padding-left: 5px;
  @include breakpoint($xs) {
    display: block;
  }
  &:after {
    content: '\e802';
    font-size: 12px;
    padding-left: 5px;
    @include fontello();
  }
}

.featured-jobs .view-content,
.featured-jobs .view-row {
  @include make-row();
}

.featured-jobs .view-col {
  @include make-sm-column(12);
  @include make-md-column(10);
  @include make-md-column-offset(1);
}

.featured-jobs .job {
  margin: 0 0 20px;
  @include make-sm-column(4);
}

/* =Image Collage
----------------------------------------------------------*/

.collage__image {
  background-image: url(../images/home/careers-602.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 175px;
  border: 3px solid $wcm-white;
  border-width: 0 0 3px;
  @include breakpoint($sm) {
    height: 300px;
  }
}

.collage__split .collage__image   {
  height: 150px;
  border-width: 0 3px 3px;
  @include breakpoint((min: 1500px)) {
    height: 300px
  }
}

.collage__split-1 {
  background-image: url(../images/home/careers-1339.jpg);
  @include breakpoint((min: 1500px)) {
    display: none;
  }
}

.collage__split-2 {
  background-image: url(../images/home/careers-425.jpg);
}

.collage__image ~ .collage__image {
  background-image: url(../images/home/careers-905.jpg);
}

/* =Misc
----------------------------------------------------------*/

.front footer {
  padding: 0;
}
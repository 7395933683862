/* =WCMC Careers Font Icon
----------------------------------------------------------*/

@font-face {
  font-family: 'wcmc-careers';
  src:url('../fonts/wcmc-careers/fonts/wcmc-careers.eot?jeqy4k');
  src:url('../fonts/wcmc-careers/fonts/wcmc-careers.eot?jeqy4k#iefix') format('embedded-opentype'),
    url('../fonts/wcmc-careers/fonts/wcmc-careers.ttf?jeqy4k') format('truetype'),
    url('../fonts/wcmc-careers/fonts/wcmc-careers.woff?jeqy4k') format('woff'),
    url('../fonts/wcmc-careers/fonts/wcmc-careers.svg?jeqy4k#wcmc-careers') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin careers-icon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wcmc-careers' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


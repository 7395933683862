/**
 * Styles for individual site components
 *
 */

/* =Site Logo
----------------------------------------------------------*/

.brand__lockup img {
  width: auto;
  height: 70px;
}

/* =Primary Navigation
----------------------------------------------------------*/

#primary-nav .level-1 {
  width: 20%;
}

/* =Drawer Navigation
----------------------------------------------------------*/

#drawer-nav .level-2 {
  @include breakpoint($sm) {
    padding: 0 15px 10px;
  }
  @include breakpoint($md) {
    width: 33%;
  }
}

/* =Featured Quotes
----------------------------------------------------------*/

.featured-quote {
  width: 90%;
  margin: 30px auto 0;
  text-align: center;
  @include breakpoint($md) {
    margin: 30px 0 0;
    text-align: right;
  }
}

.featured-quote__quote p {
  margin-bottom: 5px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.8;
}

/* =Featured Jobs Index
----------------------------------------------------------*/

.featured-jobs-index .job {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $wcm-border-gray;
  &:last-child {
    border-bottom: none;
  }
}

.job__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.job__department {
  padding: 5px 0;
}

/* =Staff Highlights
----------------------------------------------------------*/

.highlight__job-title {
  font-size: 24px;
  line-height: 36px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.highlight__main-image {
  margin-bottom: 20px;
}

.highlight__main-image img {
  @include breakpoint($sm) {
    border: 15px solid #eee;
  }
}

.highlight__pull-quote {
  float: none;
  @include breakpoint($sm) {
    width: 220px;
    margin: 30px 0 30px 30px;
    float: right;
    display: block;
  }
}

.highlight__pull-quote > div {
  color: $wcm-med-gray;
  font-size: 24px;
  line-height: 36px;
  font-family: $wcm-italic;
  border-left: 5px solid $wcm-bright-orange;
  padding: 10px 20px;
  margin: 0 0 20px;
}

.highlight__pull-quote > div > p {
  line-height: 30px;
}

/* =Staff Highlights Index
----------------------------------------------------------*/

.highlight-index .view-row {
  @include make-row(40px);
}

.highlight-teaser {
  @include make-sm-column(4, 40px);

  &:nth-child(3n+4) {
    @include breakpoint($sm) {
      clear: left;
    }
  }
}

.highlight-teaser > a {
  display: block;
  max-width: 220px;
  min-height: 340px;
  margin: 0 auto 30px;
  padding: 10px;
  background: #eee;
  @include breakpoint($sm) {
    margin: 0 0 30px;
  }
  &:hover {
    background: $wcm-border-gray;
    text-decoration: none;
  }
}

.highlight-teaser__headshot {
  position: relative;
  opacity: 0.8;
  @include transition(opacity .25s ease-in-out);
}

.highlight-teaser__view {
  display: none;
  position: absolute;
  bottom: 15px;
  left: 54px;
  font-size: 12px;
  font-weight: 600;
  color: $wcm-white;
  text-align: center;
  text-decoration: none;
  &:before {
    content: "\e900";
    position: relative;
    top: 3px;
    font-size: 16px;
    padding: 0 5px 0 0;
    @include careers-icon();
  }
}

.highlight-teaser__name {
  padding: 10px 0 5px;
  font-weight: 600;
}

.highlight-teaser__job-title {
  font-size: 12px;
  color: $wcm-black;
}

// Hover Effect

.highlight-teaser > a:hover .highlight-teaser__view {
  display: block;
}

.highlight-teaser > a:hover .highlight-teaser__headshot  {
  opacity: 1.0;
}

/* =Benefits Index
----------------------------------------------------------*/

.benefits-index {
  margin: 20px 0;
}

.benefits-index .view-row {
  @include breakpoint($md) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
  }
}

.benefit {
  @include breakpoint($md) {
    -webkit-column-break-inside:avoid;
    -moz-column-break-inside:avoid;
    -o-column-break-inside:avoid;
    -ms-column-break-inside:avoid;
    column-break-inside:avoid;
    page-break-inside: avoid;
  }
}

.benefit__title {
  font-weight: 600;
}

/* =FAQ
----------------------------------------------------------*/

.faq-list:last-child .element__toggle {
  border-width: 1px 0;
}

.faq-list:last-child .element__toggle--open {
  border-width: 1px 0 0;
}

/* =Locations
----------------------------------------------------------*/

#map {
  height: 300px;
  margin: 0 0 20px;
  @include breakpoint($sm) {
    height: 500px;
  }
}

.location {
  margin: 0 0 20px;
}

.location span {
  font-weight: 600;
}

/* =Footer Navigation
----------------------------------------------------------*/


.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: 18%;
    float: left;
  }
}

.footer-nav .menu-mlid-1347 {
  @include breakpoint($md) {
    width: 26%;
  }
}

/* =Sort Tables
----------------------------------------------------------*/

.benefits-sort .view-col,
.jobs-sort .view-col {
  @include container-wrap();
}

/* =Misc
----------------------------------------------------------*/

.select2-selection:focus {
  outline: 1px solid $wcm-dark-orange;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(207,69,32,0.6);
}


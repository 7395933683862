/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/* =WCMC Careers Font Icon
----------------------------------------------------------*/
@font-face {
  font-family: 'wcmc-careers';
  src: url("../fonts/wcmc-careers/fonts/wcmc-careers.eot?jeqy4k");
  src: url("../fonts/wcmc-careers/fonts/wcmc-careers.eot?jeqy4k#iefix") format("embedded-opentype"), url("../fonts/wcmc-careers/fonts/wcmc-careers.ttf?jeqy4k") format("truetype"), url("../fonts/wcmc-careers/fonts/wcmc-careers.woff?jeqy4k") format("woff"), url("../fonts/wcmc-careers/fonts/wcmc-careers.svg?jeqy4k#wcmc-careers") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * Styles targeting the homepage
 *
 */
/* =Featured Highlight
----------------------------------------------------------*/
.featured-highlight {
  position: relative; }

.featured-highlight img {
  width: 100%; }

.featured-highlight__welcome {
  padding: 0 20px 20px;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .featured-highlight__welcome {
      display: block;
      background: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 10%;
      left: 56%;
      width: 40%;
      max-width: 500px;
      margin: 0;
      color: #666666; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .featured-highlight__welcome .container-wrap {
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px; }
    .featured-highlight__welcome .container-wrap:before, .featured-highlight__welcome .container-wrap:after {
      content: " ";
      display: table; }
    .featured-highlight__welcome .container-wrap:after {
      clear: both; } }
  @media screen and (min-width: 768px) and (max-width: 991px) and (min-width: 768px) {
    .featured-highlight__welcome .container-wrap {
      width: 750px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) and (min-width: 992px) {
    .featured-highlight__welcome .container-wrap {
      width: 970px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) and (min-width: 1200px) {
    .featured-highlight__welcome .container-wrap {
      width: 1170px; } }

.featured-highlight__welcome h1 {
  font-size: 26px;
  font-family: "Open Sans", sans-serif;
  color: #e7751d;
  border-bottom: 2px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .featured-highlight__welcome h1 {
      font-size: 38px; } }
  @media screen and (min-width: 992px) {
    .featured-highlight__welcome h1 {
      font-size: 30px;
      padding-bottom: 10px;
      margin-bottom: 10px; } }
  @media screen and (min-width: 1135px) {
    .featured-highlight__welcome h1 {
      font-size: 38px;
      padding-bottom: 15px;
      margin-bottom: 15px; } }

.featured-highlight__welcome p {
  padding-bottom: 10px;
  color: #000000; }
  @media screen and (min-width: 992px) {
    .featured-highlight__welcome p {
      font-size: 14px;
      padding-bottom: 0; } }
  @media screen and (min-width: 1135px) {
    .featured-highlight__welcome p {
      font-size: 15px;
      padding-bottom: 15px; } }

.featured-highlight__text {
  width: 100%;
  color: #000000;
  font-size: 14px;
  padding: 10px 0; }
  @media screen and (min-width: 992px) {
    .featured-highlight__text {
      position: absolute;
      bottom: 5%;
      padding: 15px 0;
      background: rgba(255, 255, 255, 0.9); } }
  @media screen and (min-width: 1200px) {
    .featured-highlight__text {
      font-size: 16px; } }

.featured-highlight__name {
  font-size: 20px; }
  @media screen and (min-width: 992px) {
    .featured-highlight__name {
      text-align: right;
      line-height: 1.9; } }
  @media screen and (min-width: 1200px) {
    .featured-highlight__name {
      font-size: 22px; } }

.featured-highlight__pull-quote p {
  margin: 0;
  line-height: 1.6; }

@media screen and (max-width: 767px) {
  .featured-highlight__cta .btn {
    margin: 0 0 10px; } }

/* =Why WCMC Pane
----------------------------------------------------------*/
.why-wcmc {
  padding: 40px 0;
  border-top: 1px solid #dddddd; }
  @media screen and (min-width: 992px) {
    .why-wcmc {
      border: none; } }

.why-wcmc__title {
  font-size: 24px;
  padding-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .why-wcmc__title {
      padding-bottom: 10px; } }

.why-wcmc__title a {
  font-size: 16px;
  padding-left: 5px; }
  @media screen and (max-width: 767px) {
    .why-wcmc__title a {
      display: block; } }
  .why-wcmc__title a:after {
    content: '\e802';
    font-size: 12px;
    padding-left: 5px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.why-wcmc__benefit {
  text-align: center; }
  @media screen and (max-width: 767px) {
    .why-wcmc__benefit {
      margin: 20px 0; } }

.why-wcmc__benefit a {
  display: inline-block;
  text-decoration: none;
  color: #e7751d; }

.why-wcmc__benefit a:hover span {
  text-decoration: underline; }

.why-wcmc__benefit a:before {
  display: block;
  font-size: 36px;
  padding-bottom: 20px;
  color: #e7751d;
  cursor: pointer;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wcmc-careers' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.why-wcmc__benefit--comp a:before {
  content: '\e903'; }

.why-wcmc__benefit--dev a:before {
  content: '\e901'; }

.why-wcmc__benefit--balance a:before {
  content: '\e902'; }

.why-wcmc__benefit--awards a:before {
  content: '\e905'; }

/* =Find Your Place
----------------------------------------------------------*/
.find-your-place {
  position: relative; }

.find-your-place__image {
  background-image: url(../images/home/careers-1501.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 175px; }
  @media screen and (min-width: 768px) {
    .find-your-place__image {
      height: 300px; } }
  .find-your-place__image:nth-child(2) {
    background-image: url(../images/home/careers-1103.jpg);
    border-left: 3px solid #fff; }

.find-your-place__career-areas {
  padding: 0 20px; }
  @media screen and (max-width: 767px) {
    .find-your-place__career-areas {
      width: 90%;
      max-width: 315px;
      margin: 0 auto 20px; } }
  @media screen and (min-width: 768px) {
    .find-your-place__career-areas {
      position: absolute;
      top: 0;
      right: 5%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9); } }

.find-your-place__career-areas h3 {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  margin: 10px 0 5px 0; }
  @media screen and (min-width: 768px) {
    .find-your-place__career-areas h3 {
      max-width: 225px;
      text-align: center; } }

.find-your-place__career-areas ul {
  padding: 5px 0 0 20px; }
  @media screen and (min-width: 768px) {
    .find-your-place__career-areas ul {
      margin-bottom: 5px; } }

.find-your-place__career-areas li {
  padding-bottom: 8px; }

/* =Featured Jobs Home View
----------------------------------------------------------*/
.featured-jobs {
  padding: 40px 0;
  border-top: 1px solid #dddddd; }
  @media screen and (min-width: 992px) {
    .featured-jobs {
      border: none; } }

.featured-jobs .view-jobs {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .featured-jobs .view-jobs:before, .featured-jobs .view-jobs:after {
    content: " ";
    display: table; }
  .featured-jobs .view-jobs:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .featured-jobs .view-jobs {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .featured-jobs .view-jobs {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .featured-jobs .view-jobs {
      width: 1170px; } }

.featured-jobs__title {
  font-size: 24px;
  padding-bottom: 40px; }

.featured-jobs__title a {
  font-size: 16px;
  padding-left: 5px; }
  @media screen and (max-width: 767px) {
    .featured-jobs__title a {
      display: block; } }
  .featured-jobs__title a:after {
    content: '\e802';
    font-size: 12px;
    padding-left: 5px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.featured-jobs .view-content,
.featured-jobs .view-row {
  margin-left: -20px;
  margin-right: -20px; }
  .featured-jobs .view-content:before, .featured-jobs .view-content:after,
  .featured-jobs .view-row:before,
  .featured-jobs .view-row:after {
    content: " ";
    display: table; }
  .featured-jobs .view-content:after,
  .featured-jobs .view-row:after {
    clear: both; }

.featured-jobs .view-col {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .featured-jobs .view-col {
      float: left;
      width: 100%; } }
  @media (min-width: 992px) {
    .featured-jobs .view-col {
      float: left;
      width: 83.33333%; } }
  @media (min-width: 992px) {
    .featured-jobs .view-col {
      margin-left: 8.33333%; } }

.featured-jobs .job {
  margin: 0 0 20px;
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .featured-jobs .job {
      float: left;
      width: 33.33333%; } }

/* =Image Collage
----------------------------------------------------------*/
.collage__image {
  background-image: url(../images/home/careers-602.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 175px;
  border: 3px solid #fff;
  border-width: 0 0 3px; }
  @media screen and (min-width: 768px) {
    .collage__image {
      height: 300px; } }

.collage__split .collage__image {
  height: 150px;
  border-width: 0 3px 3px; }
  @media screen and (min-width: 1500px) {
    .collage__split .collage__image {
      height: 300px; } }

.collage__split-1 {
  background-image: url(../images/home/careers-1339.jpg); }
  @media screen and (min-width: 1500px) {
    .collage__split-1 {
      display: none; } }

.collage__split-2 {
  background-image: url(../images/home/careers-425.jpg); }

.collage__image ~ .collage__image {
  background-image: url(../images/home/careers-905.jpg); }

/* =Misc
----------------------------------------------------------*/
.front footer {
  padding: 0; }

/**
 * Styles for individual site components
 *
 */
/* =Site Logo
----------------------------------------------------------*/
.brand__lockup img {
  width: auto;
  height: 70px; }

/* =Primary Navigation
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 20%; }

/* =Drawer Navigation
----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  #drawer-nav .level-2 {
    padding: 0 15px 10px; } }

@media screen and (min-width: 992px) {
  #drawer-nav .level-2 {
    width: 33%; } }

/* =Featured Quotes
----------------------------------------------------------*/
.featured-quote {
  width: 90%;
  margin: 30px auto 0;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .featured-quote {
      margin: 30px 0 0;
      text-align: right; } }

.featured-quote__quote p {
  margin-bottom: 5px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.8; }

/* =Featured Jobs Index
----------------------------------------------------------*/
.featured-jobs-index .job {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dddddd; }
  .featured-jobs-index .job:last-child {
    border-bottom: none; }

.job__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600; }

.job__department {
  padding: 5px 0; }

/* =Staff Highlights
----------------------------------------------------------*/
.highlight__job-title {
  font-size: 24px;
  line-height: 36px;
  margin-top: 20px;
  margin-bottom: 10px; }

.highlight__main-image {
  margin-bottom: 20px; }

@media screen and (min-width: 768px) {
  .highlight__main-image img {
    border: 15px solid #eee; } }

.highlight__pull-quote {
  float: none; }
  @media screen and (min-width: 768px) {
    .highlight__pull-quote {
      width: 220px;
      margin: 30px 0 30px 30px;
      float: right;
      display: block; } }

.highlight__pull-quote > div {
  color: #666666;
  font-size: 24px;
  line-height: 36px;
  font-family: "1898Sans-Italic", sans-serif;
  border-left: 5px solid #e7751d;
  padding: 10px 20px;
  margin: 0 0 20px; }

.highlight__pull-quote > div > p {
  line-height: 30px; }

/* =Staff Highlights Index
----------------------------------------------------------*/
.highlight-index .view-row {
  margin-left: -20px;
  margin-right: -20px; }
  .highlight-index .view-row:before, .highlight-index .view-row:after {
    content: " ";
    display: table; }
  .highlight-index .view-row:after {
    clear: both; }

.highlight-teaser {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .highlight-teaser {
      float: left;
      width: 33.33333%; } }
  @media screen and (min-width: 768px) {
    .highlight-teaser:nth-child(3n+4) {
      clear: left; } }

.highlight-teaser > a {
  display: block;
  max-width: 220px;
  min-height: 340px;
  margin: 0 auto 30px;
  padding: 10px;
  background: #eee; }
  @media screen and (min-width: 768px) {
    .highlight-teaser > a {
      margin: 0 0 30px; } }
  .highlight-teaser > a:hover {
    background: #dddddd;
    text-decoration: none; }

.highlight-teaser__headshot {
  position: relative;
  opacity: 0.8;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out; }

.highlight-teaser__view {
  display: none;
  position: absolute;
  bottom: 15px;
  left: 54px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-decoration: none; }
  .highlight-teaser__view:before {
    content: "\e900";
    position: relative;
    top: 3px;
    font-size: 16px;
    padding: 0 5px 0 0;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wcmc-careers' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.highlight-teaser__name {
  padding: 10px 0 5px;
  font-weight: 600; }

.highlight-teaser__job-title {
  font-size: 12px;
  color: #000000; }

.highlight-teaser > a:hover .highlight-teaser__view {
  display: block; }

.highlight-teaser > a:hover .highlight-teaser__headshot {
  opacity: 1.0; }

/* =Benefits Index
----------------------------------------------------------*/
.benefits-index {
  margin: 20px 0; }

@media screen and (min-width: 992px) {
  .benefits-index .view-row {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px; } }

@media screen and (min-width: 992px) {
  .benefit {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    -o-column-break-inside: avoid;
    -ms-column-break-inside: avoid;
    column-break-inside: avoid;
    page-break-inside: avoid; } }

.benefit__title {
  font-weight: 600; }

/* =FAQ
----------------------------------------------------------*/
.faq-list:last-child .element__toggle {
  border-width: 1px 0; }

.faq-list:last-child .element__toggle--open {
  border-width: 1px 0 0; }

/* =Locations
----------------------------------------------------------*/
#map {
  height: 300px;
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    #map {
      height: 500px; } }

.location {
  margin: 0 0 20px; }

.location span {
  font-weight: 600; }

/* =Footer Navigation
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px; } }

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 18%;
    float: left; } }

@media screen and (min-width: 992px) {
  .footer-nav .menu-mlid-1347 {
    width: 26%; } }

/* =Sort Tables
----------------------------------------------------------*/
.benefits-sort .view-col,
.jobs-sort .view-col {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .benefits-sort .view-col:before, .benefits-sort .view-col:after,
  .jobs-sort .view-col:before,
  .jobs-sort .view-col:after {
    content: " ";
    display: table; }
  .benefits-sort .view-col:after,
  .jobs-sort .view-col:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .benefits-sort .view-col,
    .jobs-sort .view-col {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .benefits-sort .view-col,
    .jobs-sort .view-col {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .benefits-sort .view-col,
    .jobs-sort .view-col {
      width: 1170px; } }

/* =Misc
----------------------------------------------------------*/
.select2-selection:focus {
  outline: 1px solid #cf4520;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(207, 69, 32, 0.6); }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }
